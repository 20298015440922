import {Link} from "#/src/components/Link";
import AppConstants from "#/src/utils/app-constants";
import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext, DataWrapped} from "#/src/definitions/AppDefinitions";
import {navigate} from "vike/client/router";
import {toast} from "react-toastify";
import {LoadableButton} from "#/src/components/LoadableButton";
import React, {useState} from "react";
import {ApiGenericPageData} from "#/src/definitions/ApiDefinitions";
import {BrandLogo} from "#/src/components/BrandLogo";
import {useData} from "vike-react/useData";
import {AttachmentNamespace, Attachments} from "#/src/utils/attachment-storage";
import {Captcha} from "#/src/components/Captcha";
import {LoaderSvg} from "#/src/components/LoaderSvg";
import {TwoFactorCodeReturn, TwoFactorModal, TwoFactorStatus} from "#/src/components/TwoFactorModal";
import {doIntercept2FA, is2FAInvalid} from "#/src/utils/2fa-response-interceptor";
import {AdWrapper} from "#/src/components/AdWrapper";
import styled from "@emotion/styled";

const LoginAdWrapper = styled(AdWrapper)`
    width: 100%;
    height: 100%;
    background-image: url(${props => Attachments.getUrl(AttachmentNamespace.AD, props.ad)});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
`;

export function Content() {
  const {data} = useData() as DataWrapped<ApiGenericPageData>;
  const {user} = usePageContext() as AppPageContext;
  const [token, setToken] = useState<string | null>();
  const [isTwoFactor, setTwoFactor] = useState(false);

  async function onLogin(code: TwoFactorCodeReturn | null) {
    const login = (document.getElementById("login") as HTMLInputElement).value;
    const password = (document.getElementById("password") as HTMLInputElement).value;
    const rememberMe = (document.getElementById("rememberMe") as HTMLInputElement).checked;
    const response = await fetch(`/service/login`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({token, login, password, rememberMe, twoFactorCode: code?.code, twoFactorType: code?.type}),
      credentials: "same-origin"
    });
    if (response.ok) {
      if (code && code?.type == "BACKUP_CODES") {
        toast("Wykorzystałeś kod zapasowy, został on teraz unieważniony.");
        setTimeout(() => {
          setTwoFactor(false);
          navigate("/");
        }, 1750);
      } else {
        setTwoFactor(false);
        await navigate("/");
      }
      return;
    }
    const errorResponse = await response.json() as any;
    if (doIntercept2FA(errorResponse, () => setTwoFactor(true))) {
      return;
    }
    if(is2FAInvalid(errorResponse)) {
      return TwoFactorStatus.INVALID;
    }
    if (errorResponse && errorResponse.errors) {
      errorResponse.errors.forEach(err => toast(err));
    }
  }

  function onEnterCheck(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      document.getElementById("loginButton").click();
    }
  }

  const ad = data.adData
    .filter(a => a.placement == "LOGIN_PAGE")
    .filter(a => a.theme == (user.lightMode ? "LIGHT" : "DARK"))[0];

  function render() {
    return <section className="login-wrapper">
      <TwoFactorModal id={"login2fa"} isOpen={isTwoFactor} onHide={() => setTwoFactor(false)} onCodeProvide={onLogin}/>
      <div className="container-fluid p-0 h-100">
        <div className={`row g-0 h-100 ${!ad && "justify-content-center"}`}>
          <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-12 h-100">
            <div className="login">
              <div className="login-brand">
                <Link href="/">
                  <BrandLogo/>
                </Link>
              </div>
              <div className="login-social">
                <p className="subtitle">Loguj się szybciej za pomocą social mediów</p>
                <div className="login-social-flex">
                  <a href={`${AppConstants.GOOGLE_OAUTH_LINK}&state=auth_basic`} role="button" className="social-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
                      <path fill="currentColor"
                            d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301c1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/>
                    </svg>
                  </a>
                  <a href={`${AppConstants.FACEBOOK_OAUTH_LINK}&state=auth_basic`} role="button" className="social-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                      <path fill="currentColor"
                            d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4z"/>
                    </svg>
                  </a>
                  <a href={`${AppConstants.DISCORD_OAUTH_LINK}&state=auth_basic`} role="button" className="social-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                      <path fill="currentColor"
                            d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02M8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12m6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12"/>
                    </svg>
                  </a>
                  <a href={AppConstants.STEAM_OPENID_AUTH_LINK} role="button" className="social-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14">
                      <path fill="currentColor" fillRule="evenodd"
                            d="M10.248.512a3.312 3.312 0 0 1 .776 6.567L8.51 9.822a2.835 2.835 0 0 1-5.237 2.12l1.423.474a1.789 1.789 0 0 0 1.38-3.29l-1.745-.893a2.823 2.823 0 0 1 1.307-.405l.016-.001l1.537-3.349A3.312 3.312 0 0 1 10.248.512m.292 4.525a1.32 1.32 0 1 0-.152-2.635a1.32 1.32 0 0 0 .152 2.635M0 7.422l.01 2.275l5.176 1.721a.6.6 0 0 0 .463-1.104z"
                            clipRule="evenodd"/>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="divider-flex">
                <div className="line"></div>
                <span>lub zaloguj się przez e-mail</span>
                <div className="line"></div>
              </div>
              <form className="login-form">
                <div className="mb-3">
                  <label htmlFor="login" className="form-label">Adres e-mail</label>
                  <input type="email" className="form-control" id="login" placeholder="Wpisz swój login" onKeyDown={onEnterCheck}/>
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Hasło</label>
                  <input type="password" className="form-control" id="password" placeholder="Wprowadź hasło" onKeyDown={onEnterCheck}/>
                </div>
                <div className="form-flex">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="rememberMe"/>
                    <label className="form-check-label" htmlFor="rememberMe">
                      Zapamiętaj mnie
                    </label>
                  </div>
                  <a href={"/reset-password"} className="reset-link">
                    Nie pamiętam hasła
                  </a>
                </div>
              </form>
              {renderLoginButton()}
              <div className="sign-up">
                <span>Nie masz jeszcze konta? <a href={"/register"}>Zarejestruj się</a></span>
              </div>
              <div><Captcha onSuccess={(token) => setToken(token)}/></div>
            </div>
          </div>
          {ad && <LoginAdWrapper ad={ad} className="col-xxl-8 col-xl-7 col-lg-6 d-lg-block d-md-none"/>}
        </div>
      </div>
    </section>
  }

  function renderLoginButton() {
    if (!token) {
      return <LoadableButton id={"loginButton"} className={"btn btn-primary w-100 my-3"} onClick={void 0} disabled>Wykonuję CAPTCHĘ <LoaderSvg/></LoadableButton>
    }
    return <LoadableButton id={"loginButton"} className={"btn btn-primary w-100 my-3"} onClick={() => onLogin(null)}>Zaloguj się</LoadableButton>
  }

  return render();
}